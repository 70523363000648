@import '../../../assets/scss/global.scss';

.maintenance-overview{

    .calendar-link{
        color: $blue;
        font-size: 2.2rem;
        position: relative;
        left: 6px;
        top: 1px;
    }

    .filters{

        .MuiButton-root{
            color: $black;
            border-color: #AAAAAA;
        }

        .active{
            border-color: $blue;
            background: $blue;
            color: $white;
        }
    }

    .MuiButton-outlined{
        padding: 2px 15px;
    }

    .text-wrap{
        overflow-wrap: break-word;

        &.title{
            line-height: 38px;
        }
    }

    .text-ellipsis{
        overflow:hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        &.title{
            line-height: 38px;
        }
    }
    
    .actions{

        .action-item{
    
            a{
                text-decoration: none;
            }
    
            span{
                text-align: center;
                position: relative;
                font-size: 1.1rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: uppercase;
                color: $blue;
                cursor: pointer;
    
                // &:hover{
                //     text-decoration: none;
                // }
            }
    
            // &:before{
            //     content: 'o';
            //     color: $blue;
            //     font-size: 1.3em;
            //     font-weight: bold;
            //     font-family: 'Arial';
            //     text-transform: lowercase;
            //     margin-right: 3px;
            // }
    
            &.disabled{
    
                span{
                    cursor: not-allowed;
                    color: $disabled;
    
                    &:hover{
                        text-decoration: none;
                    }
                }
    
                &:before{
                    color: $disabled;
                }
            }
        }
    }
    
    .filters{
    
        .filter-label{
            font-size: 1.2em;
            text-transform: uppercase;
        }
    
        .filter-select{
    
            .form-select{
                
                .MuiSelect-select{
                    padding: 5px;
                    font-size: 0.8em;
                    font-weight: bold;
                    color: $blue;
                }
            }
        }
    }

    .toggle-detail{
        padding-top: 25px;
        padding-left:15px;
        height: 78%;

        .header-detail{
            margin-bottom: 25px;

            .machineNo{
                font-weight:500;
                font-size:2.4rem;
                margin-bottom: 5px;
            }

            .status{
                font-weight:500;
                font-size:2.3rem;
                line-height: 38px;

                &.active{
                    color: $status-active;
                }

                &.in-repair{
                    color: $status-in-repair;
                }

                &.not-working{
                    color: $status-not-working;
                    font-size:2rem;
                }
            }
    
            .header-label{
                font-size: 1.4rem;
                font-weight: 400;
                margin-bottom: 5px;
            }

        }

        .body-detail{
            margin-bottom: 25px;

            .body-label{
                font-size: 1.2rem;
                color: $gray;
            }

            .body-value{
                font-size: 1.2rem;
                color: $black;
            }
        }
    }
}