/* BRAND COLORS */
$black: #000000;
$white: #ffffff;
$blue: #0F75BC;
$gray: #AAAAAA;
$disabled: #AAAAAA;

/* LOGIN */
$login-background: #172937;
$login-container:#264963;

/* DRAWER */
$drawer-header: #D7D7D7;

/* FORM COLORS */

$form-label-gray:#9B9B9B;

/* TEMPLATE COMPONENT COLORS */
$template-header: #F2F2F2;
$blue-header: #0F75BC;
$blue-circle: #87BADE;

/* MAIN PAGE HEADER COLORS */

$main-page-header: #F2F2F2;
$hamburger: #d7d7d7;
$arrow-back: #E6E6E6;

/* MAIN TABLE STATUS COLORS */
$status-active:#70B603;
$status-in-repair: #F59A23;
$status-repair: #FF9100;
$status-not-working:#D9001B;
$status-not-working-gray:#797979;
$status-re-opened:#FF435B;
$row-selected:rgba(128, 255, 255, 0.25);

/* LINE STATUS OVERVIEW COLORS */
$line-tab: #F2F2F2;
$line-tab-highlight: #D9001B;
$ticket-header-orange: #F2A104;
$ticket-header-red: #D9001B;
$line-machine-default: #D7D7D7;
$line-machine-empty: #797979;
// $carousel-dot-default: #0000001A;
$carousel-dot-default: #9E9E9E;
$carousel-dot-hover: #3295DD;
$carousel-dot-active: #0F75BC;
$line-text-blue:#0F75BC;

/* DASHBOARD COLORS */
$dashboard-background:#172937;
$dashboard-menu: #1E394D;
$dashboard-card: #264963;
$dashboard-light-blue: #366a90;
$dashboard-green:#03B615;
$dashboard-red:#D9001B;
$dashboard-stripe-blue: #3C5C73;
$dashboard-yellow: #FFEB00;