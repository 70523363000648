@import '../../../../assets/scss/global.scss';

.checklist-form{

    .file-hidden{
        display:none;
    }
    
    .title-blue{
        color: $blue;
        font-size:3rem;
        margin-top: 25px;
        margin-bottom: 20px;
    }.button-group{
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: right;
    
        .MuiButtonBase-root{
            box-shadow: none;
    
            &:hover{
                box-shadow: none;
            }
    
            &.MuiButton-contained{
                font-size: 1.3rem;
                color: #0F75BC;
                margin-right: 15px;
                padding-right: 30px;
                padding-left: 30px;
    
                &:last-child{
                    margin-right: 0px;
                }
            }


    
            &.MuiButton-containedPrimary{
                background-color: $blue;
                color: $white;
                font-weight: bold;
    
                &:hover{
                    background-color: darken($blue,5%);
                }
            }
        }
    }
    
    .status{
        font-size: 1.4rem;
        font-weight:bold;
        position:relative;
        top: 7px;

        &.active{
            color: $status-active;
        }

        &.in-repair{
            color: $status-in-repair;
        }

        &.not-working{
            color: $status-not-working;
        }
        &.not-working-gray{
            color: $status-not-working-gray;
        }
        &.repair{
            color: $status-repair;
        }
        &.maintenance{
            color: $blue;
        }
    }

    .text-ellipsis{
        overflow:hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display:block;

        &.title{
            line-height: 38px;
        }
    }
    
    .actions{

        .action-item{
    
            a{
                text-decoration: none;
            }
    
            span{
                text-align: center;
                position: relative;
                font-size: 1.1rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: uppercase;
                color: $blue;
                cursor: pointer;
    
                // &:hover{
                //     text-decoration: none;
                // }
            }
    
            // &:before{
            //     content: 'o';
            //     color: $blue;
            //     font-size: 1.3em;
            //     font-weight: bold;
            //     font-family: 'Arial';
            //     text-transform: lowercase;
            //     margin-right: 3px;
            // }
    
            &.disabled{
    
                span{
                    cursor: not-allowed;
                    color: $disabled;
    
                    &:hover{
                        text-decoration: none;
                    }
                }
    
                &:before{
                    color: $disabled;
                }
            }
        }
    }
    
    .filters{
    
        .filter-label{
            font-size: 1.2rem;
            text-transform: uppercase;
        }
    
        .filter-select{
    
            .form-select{
                
                .MuiSelect-select{
                    padding: 5px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    color: $blue;
                }
            }
        }
    }
    
    .form-component{
        margin-top:15px !important;
        margin-bottom:25px !important;

        .form-control{
            margin-top:5px;
    
            &:first-child{
                margin-top:0px;
            }
    
            .form-label{
                position:relative;
                top: 8px;
                color: $form-label-gray;
                font-size:1.4rem;

                &.extra-margin{
                    margin-bottom: 17px;
                }
            }
    
            .value-label{
                position:relative;
                top: 8px;
                color: $black;
                font-size:1.4rem;
    
                strong{
                    font-weight: 700;
                }
            }
        
            .MuiInputBase-root{
                height: 40px;
            }
        }
    }
}