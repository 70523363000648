@import '../../../../assets/scss/global.scss';

$black-text: #515151;
$tab-blue:rgba(22, 137, 250, 0.2);
:root{
    --mLeft: 10px
}
.user-access-config{

    .Mui-disabled{
        color: #a1a1a1 !important;
    }

    .user-access-config-body{
        padding: 10px;
        background: $white;
        color: $black-text;

        .title{
            font-size: 1.7rem;
            margin-left: var(--mLeft);
        }

        .MuiTabs-root{
            border-bottom: 1px solid #e1e1e1;
            margin-left: var(--mLeft);
            margin-right: var(--mLeft);
            .MuiTab-root{
                font-size: 1.1rem;
                margin-right: 5px;
                border-bottom: 4px solid $tab-blue;
                opacity:1;
    
                &.Mui-selected{
                    color: $blue;
                }
            }

        }

        .MuiTabs-indicator{
            transition-duration: 0s;
            background-color: $blue;
            height: 4px;
        }
    }
}