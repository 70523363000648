@import '../../assets/scss/global.scss';

.user-blue-header{
    height: 42px;
    width: 100%;
    display: block;
    background: $blue-header;

    .flag-dropdown{
        border-radius: 150px;
        width: 2rem;
        height: 2rem;
        top: -4px;
        left: -4px;
        display: block;
    }

    .language-name{
        display: block;
        margin-left: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 30px;
        text-align: center;
    }

    .logo{
        position: relative;
        top: 1px;
        left: 5px;

        .square1{
            position: relative;
            display: inline-block;
            height: 10px;
            width: 10px;
            background: $white;

            &:after{
                content: '';
                position: absolute;
                display: inline-block;
                height: 10px;
                width: 10px;
                top: 13px;
                left:0px;
                background: $white;
            }
        }

        .square2{
            position: relative;
            display: inline-block;
            height: 10px;
            width: 10px;
            left: 3px;
            background: $white;

            &:after{
                content: '';
                position: absolute;
                display: inline-block;
                height: 10px;
                width: 10px;
                top: 13px;
                left:0px;
                background: $white;
            }
        }
    }

    .user-header-title{
        position: relative;
        top: 7px;
        left: 5px;
        font-size: 1.4rem;
        font-weight: 500;
        color: $white;
    }

    .circles{
        position: relative;
        top: 8px;
        display: inline-flex;
        float: right;
        .circle{
            display: inline-block;
            background-color: $blue-circle;
            height: 25px;
            width: 25px;
            border-radius: 50px;
        }
    }

    .username{
        font-size: 1.4rem;
        color: $white;
        display: inline-flex;
        .icon-center{
            text-align:center;
            width: fit-content;
            padding: 0px 6px;
        }

        .user-icon{
            font-size: 2rem;
            position: relative;
            top: 1px;
            left: 0px;
        }
    }

    .logout{

        &:before{
            cursor: pointer;
            content: url('https://api.iconify.design/ion-md-log-out.svg?color=%23ffffff&height=25');
            vertical-align: -0.125em;
        };
        display: inline-flex;
        padding: 0px 12px;
    }
}

.language{
    // margin-top: -5px;
    float: right;
    
    .language-label{
        color: $white;
        opacity: 0.79;
        position: relative;
        // top:7px;
    }

    .MuiInputBase-root{
        background: transparent !important;
        max-width: 125px;

        &::after, &::before{
            display:none;
        }

        .MuiSelect-root{
            padding: 0px 0px;
            color: $white;
            border-color: transparent;
            text-align:left;
            font-weight: 400 !important;
            font-size: 1.3rem !important;
        }

        .MuiSvgIcon-root{
            color: $white;
            position: relative;
            // top: 10px;
        }

        .MuiSelect-root {
            background:transparent;
        }
    }
}

.factories{
    // margin-top: -5px;
    .language-label{
        color: $white;
        opacity: 0.79;
        position: relative;
        top:7px;
    }

    .MuiInputBase-root{
        background: transparent !important;

        &::after, &::before{
            display:none;
        }

        .MuiSelect-root{
            padding: 0px 6px;
            display: inline-flex;
            color: $white;
            border-color: transparent;
            font-weight: 400 !important;
            font-size: 1.3rem !important;
            text-align:left;
        }

        .MuiSvgIcon-root{
            color: $white;
            position: relative;
            // top: 10px;
        }

        .MuiSelect-root {
            background:transparent;
        }
    }
    .inlineFLex{
        display: inline-flex;
        padding: 0px 3px;
    }
}