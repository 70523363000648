@import '../../../../../assets/scss/global.scss';

$border-gray:#DFDFDF;

.attachment-form{
    background: #F7F7FB;

    .filename{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .thumbnail{
        position: relative;
        width: 150px;
        height: 150px;

        &.pdf{
            width: 100px;
            height: 150px;
        }

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .close{
            position: absolute;
            top: 5px;
            right: 5px;

            &.pdf{
                top: 3px;
                right: -20px;
            }

            &:before{
                content: '';
                display: inline-block;
                position: absolute;
                top: 6px;
                right: 6px;
                background: #fff;
                height: 0.7rem;
                width: 0.7rem;
                z-index: 2;
            }

            svg{
                top: 0px;
                right: 0px;
                position: absolute;
                color: red;
                font-size: 1.7rem;
                z-index: 9999;
                cursor: pointer;
            }
        }
    }
    
    .dropzone-container{
        position: relative;
        padding:10px;
        padding-bottom: 40px;
        border:  5px dashed $border-gray;
        border-radius: 4px;
        text-align: center;

        .drag-drop-label{

            .icon{

                .MuiSvgIcon-root{
                    font-size: 4rem;
                    color: #999999;
                }
            }

            .label{
                font-size: 1.5rem;
                color: #515151;
                font-weight: bold;
            }

            .or{
                font-size: 1.1rem;
                color: #515151;
                margin-top:10px;
                margin-bottom:10px;
            }
        }

        .dropzone{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border:0px;

            Button{
                position: absolute;
                bottom: 10px;
                font-size: 1.2rem;
                background-color:#1689FA;
                border-radius: 4px !important;
                width: 94%;
                left:3%;
            }
        }
    }

    .file-format{
        margin-top: 5px;
        font-size: 1.1rem;
        color: #515151;
        text-align:center;
    }

    a{
        text-decoration: none;
    }

    .form-container{
        width: 360px;
        display: block;
        margin: 0 auto;

        .body{
            padding: 25px 25px 40px 25px;

            &.end{
                margin-bottom:80px;
            }

            .title{
                font-size: 1.5rem;
                margin-bottom: 15px;
            }

            .MuiFormLabel-root{
                color: #757575;
                font-size:1.2rem;
            }

            .control-row{
                margin-bottom: 10px;
            }

            input{

                &::placeholder{
                    color: rgba(117, 117, 117, 0.4) !important;
                }
            }
        }
    }

    .footer-actions{
        width: 100%;
        padding: 10px;

        .cancel{
            font-size: 1.2rem;
            border-radius: 4px !important;
        }

        .save{
            font-size: 1.2rem;
            background:#1689FA;
            color: $white;
            border-radius: 4px !important;
        }
    }

    .MuiFormLabel-asterisk{
        color: red !important;
    }

    .MuiFormLabel-root{
        transition: 0.3s ease-out all;
    }

    .label-green{

        .MuiFormLabel-root{
            color: #4CAF50 !important;
        }
    }

    .radioButton{
        position: relative;
        bottom: 9px;
    }

    .statusDetails{
        padding-bottom: 10px;
    }
    .space{
        padding: 10px;
    }
}