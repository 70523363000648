@import "../../../../../assets/scss/global.scss";

$black-text: #515151;
$tab-blue: rgba(22, 137, 250, 0.2);

.role-form-config {
    background: #f7f7fb;

    .add-form{
        height: 98% !important;
    }

    .edit-form{
        height: 65%;
    }

    .MuiFormLabel-asterisk{
        color: red !important;
    }

    .MuiFormLabel-root{
        color: rgba(0, 0, 0, 0.54) !important;
        transition: 0.3s ease-out all;
    }

    .label-green{

        .MuiFormLabel-root{
            color: #4CAF50 !important;
        }
    }

    .status-form{
        padding: 15px;
        margin-top: 15px;
    }

    .user-info{
        margin-top: 25px;

        .small-label{
            color: #8C8C8C;
            font-size: 1rem;
        }

        .loginId{
            color: #000000;
        }

        .change-password{
            color: #1689FA;
            text-decoration:none;
            font-size:1rem;
            text-transform:uppercase;
            font-weight: 500;

            &:hover{
                color: darken(#1689FA,5%);
            }
        }
    }

    .profile-image {
        position: relative;
        
        .user-image{
            position: absolute;
            left: 50%;
            top: 55%;
            margin-left: -52px;
            width: 104px;
            height: 104px;
            border-radius: 250px;
            background: #C4BA82;
            border: 6px solid #e3e3e3;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    
    .footer-actions{
        transition: 0.3s ease-out all;
        opacity: 0;
        background: #F7F7FB;
        position: fixed;
        bottom:0px;
        left: 0px;
        width: 100%;
        padding: 10px;

        &.show{
            opacity:1;
        }

        .cancel{
            font-size: 1.2rem;
            border-radius: 4px !important;
        }

        .save{
            font-size: 1.2rem;
            background:#1689FA;
            color: $white;
            border-radius: 4px !important;
        }
    }

    .user-profile-form{
        padding: 14px 25px 25px 25px;

        .profile-header{
            min-height: 60px;

            .title{
                // font-size: 1.6rem;
                color: #515151;
                font-weight: 500;
                font-size: 1.4rem;
            }
        }

        .profile-body{
            min-height: 200px;

            .control-row{
                margin-bottom:10px;
            }
        }
    }

    .camera-icon{
        background: #fff;
        border-radius: 100%;
        padding: 7px 7px;
        border: 1px solid #1E3C50;
    }

    .banner-icon{
        cursor: pointer;
        position: absolute;
        left: 84%;
        top: 5%;

        img{
            position: relative;
            top: 2px;
        }
    }

    .profile-icon{
        cursor: pointer;
        position: absolute;
        left: 70px;
        top: 73px;

        img{
            position: relative;
            top: 2px;
        }
    }

    .hidden-file{
        display: none;
    }

    .hidden-file-trigger{
        cursor: pointer;
    }

    .solution-name{
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        position: relative;
        top:75px;
        left:10px
    }

    .factory-code{
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        position: relative;
        top:75px;
        left:10px;
        color:#8C8C8C
    }

    .solution-config {
        padding: 15px;

        .title {
            font-weight: bold;
        }
    }

    .factory-sublist-body {
        padding: 5px;
        color: $black-text;

        .title {
            font-size: 1.7rem;
        }
    }

    .banner-detail {
        margin-bottom: 15px;
        min-height: 370px;

        .banner-image {
            position: relative;

            .banner {
                background: rgba(107, 107, 107, 0.30);
                height: 160px;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .factory-image {
                position: absolute;
                left: 50%;
                top: 55%;
                margin-left: -52px;
                width: 104px;
                height: 104px;
                border-radius: 250px;
                background: #e3e3e3;
                border: 6px solid #e3e3e3;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }

        .factory-name {
            position: relative;
            top: 80px;
            text-align: center;
            font-size: 2.1rem;
            color: #515151;
        }
    }

    .factory-detail {
        padding: 15px;
    }

    .checkbox-label {
        position: relative;
        top: 7px;
        font-size: 1.2rem;
    }

    .factory-table {
        color: #515151;
        font-weight: 500;
        font-size: 1.4rem;
        margin-left: 14px;
        padding-top: 14px;
        padding-bottom: 14px;
        }
        .tableContainer{
            .MuiPaper-elevation1{
                box-shadow: none;
            }
            .MuiTableContainer-root {
                height: 440px;
            }
        }
}

@media only screen and (min-width: 1600px){
    .role-form-config{
        .factory-sublist-body{
            margin-bottom: 25px;
        }
        
        .MuiTableContainer-root {
            height: 520px !important;
        }
    
        .edit-form{
            height: 70% !important;
        }

    }
}