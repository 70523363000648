@import '../../../../assets/scss/global.scss';

.ticket-detail{
    
    .title-blue{
        color: $blue;
        font-size:3rem;
        margin-bottom: 10px;
    }

    .text-ellipsis{
        overflow:hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        max-height: 50px;
        display: block;
        white-space: nowrap;

        &.title{
            line-height: 38px;
        }
    }

    .actions{

        .ticket-action-item{
    
            a{
                text-decoration: none;
            }
    
            span{
                text-align: center;
                position: relative;
                font-size: 1rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: uppercase;
                color: $blue;
                cursor: pointer;
            }
    
            &.disabled{
    
                span{
                    cursor: not-allowed;
                    color: $disabled;
    
                    &:hover{
                        text-decoration: none;
                    }
                }
    
                &:before{
                    color: $disabled;
                }
            }
        }
    }

    .extra-margin-top{
        margin-top: 10px;
    }

    .textarea{

        .MuiInputBase-root{ 
            height:50px !important;
        }
    }

    .ticket-status{
        @extend .text-align-center;
        position:relative;
        top: -15px;
        font-size: 2rem;
        color: $white;
        padding: 10px;
        background-color: $gray;

        &.reported{
            background-color: $status-not-working;
        }

        &.in-repair{
            background-color: $status-in-repair;
        }

        &.closed{
            background-color: $status-active;
        }

        &.re-opened{
            background-color: $status-re-opened;
        }
    }
    
    .button-group{
        margin-top: 40px;
        margin-bottom: 25px;
        text-align: right;
    
        .MuiButtonBase-root{
            box-shadow: none;
    
            &:hover{
                box-shadow: none;
            }
    
            &.MuiButton-contained{
                font-size: 1.5rem;
                color: $blue;
                margin-right: 15px;
                padding-right: 60px;
                padding-left: 60px;
    
                &:last-child{
                    margin-right: 0px;
                }
            }
    
            &.MuiButton-containedPrimary{
                background-color: $blue;
                color: $white;
                font-weight: bold;
    
                &:hover{
                    background-color: darken($blue,5%);
                }
            }
        }
    }
    
    .form-component{
        margin-top:10px !important;
        margin-bottom:10px !important;
    
        .form-control{
            // margin-top:5px;
    
            &:first-child{
                margin-top:0px;
            }
    
            .form-label{
                position:relative;
                top: 8px;
                color: $form-label-gray;
                font-size:1.2rem;
            }
    
            .value-label{
                position:relative;
                top: 8px;
                color: $black;
                font-size:1.2rem;

                &.clickable{
                    color: $blue;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        
            .MuiInputBase-root{
                height: 40px;
            }
        }
    }

    .rounded-chip-danger {
        background-color: #D9001B;
        color: white !important;
        padding: 2px 6px;
    }

    .rounded-chip-success {
        background-color: #06bc40;
        color: white !important;
        padding: 2px 6px;
    }
}