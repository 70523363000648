@import '../../../../../assets/scss/global.scss';

$border-gray:#DFDFDF;

.machine-sub-type-form{
    background: #F7F7FB;

    a{
        text-decoration: none;
    }

    .form-container{
        width: 360px;
        display: block;
        margin: 0 auto;

        .body{
            padding: 25px 25px 40px 25px;

            &.end{
                margin-bottom:80px;
            }

            .title{
                font-size: 1.5rem;
                margin-bottom: 15px;
            }

            .MuiFormLabel-root{
                color: #757575;
                font-size:1.2rem;
            }

            .control-row{
                margin-bottom: 10px;
            }

            input{

                &::placeholder{
                    color: rgba(117, 117, 117, 0.4) !important;
                }
            }
        }
    }

    .footer-actions{
        background: $white;
        position: fixed;
        bottom:0px;
        left: 0px;
        width: 100%;
        padding: 10px;

        .cancel{
            font-size: 1.2rem;
            border-radius: 4px !important;
        }

        .save{
            font-size: 1.2rem;
            background:#1689FA;
            color: $white;
            border-radius: 4px !important;
        }
    }

    

    .MuiFormLabel-asterisk{
        color: red !important;
    }

    .MuiFormLabel-root{
        transition: 0.3s ease-out all;
    }

    .label-green{

        .MuiFormLabel-root{
            color: #4CAF50 !important;
        }
    }

    .radioButton{
        position: relative;
        bottom: 9px;
    }

    .statusDetails{
        padding-bottom: 10px;
    }
    .space{
        padding: 10px;
    }
}