@import "../../../assets/scss/global.scss";

$dashboard-card-min-height: 23.4vh;
$tvModeHeight: $dashboard-card-min-height * 3;
$minHeight: 1169;
$maxHeight: 1830;
@mixin font-responsive($size, $maxSize){
    font-size: calc(#{$size}px + (#{$maxSize} - #{$size}) * ((100vw - #{$minHeight}px) / (#{$maxHeight} - #{$minHeight})));
}
@mixin cell-small-text {
    text-transform: uppercase;
    font-size: 0.9em;
}
body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.ellipsisText{
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notfound {
    text-align: center;
    font-size: 50px;
    vertical-align: middle;
    padding: 20%
}
.dashboard {
    background-color: $dashboard-background;
    padding: 25px 45px;
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .blue-header{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;

        &.hidden{
            display:none;
        }
    }

    a {
        text-decoration: none;
    }

    .dashboard-header {
        margin-top: 4vh;
        margin-bottom: 27px; //47
        &.header-tv {
            margin-top: 15px;
            margin-bottom: 20px;
        }
        .dashboard-dates {
            font-size: 2em;
            width: 20%;
            // width: 23%;
            float: left;
            padding-top: 6px;
        }

        .dashboard-build-left {
            float: left;
            padding-top: 6px;
            // width: 65%;
        }
        .dashboard-build-right {
            padding-top: 6px;
            float: right;
            // width: 65%;
        }

        .dashboard-title {
            font-size: 2.5em;
            text-transform: uppercase;
            padding-top: 6px;
        }

        .dashboard-tv-mode {
            font-size: 2em;
            color: $dashboard-green;
            text-transform: uppercase;
            font-weight: 500;
            cursor: pointer;
            text-decoration: none;
            padding-top: 6px;

            &.hidden{
                display: none;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .dashboard-body {
        .body-container {
            margin-bottom: -15px;
        }
        .dots-bottom {
            bottom: 50px;
            position: absolute;
            left: -10px;
        }
        .dots-tvMode {
            bottom: 80px;
            position: absolute;
            left: -10px;
        }
        &.body-tvMode{
            margin-top:5vh;
        }
        .carousel-dots {
            li {
                width: 10px;
                .BrainhubCarousel__dot {
                    &:before {
                        width: 5px;
                        height: 5px;
                        background-color: #ffffff;
                        opacity: 0.39;
                    }

                    &.BrainhubCarousel__dot--selected {
                        &:before {
                            opacity: 1;
                            background-color: #ffffff;
                        }
                    }
                }
            }
        }

        .menu-icon {
            margin-right: 10px;
            vertical-align: middle;
            width: 2.3rem;
            height: 2.3rem;
        }

        .column {
            margin-bottom: -15px;
        }

        .card {
            display: flex;
            flex-direction: column;
            background-color: $dashboard-card;
            padding: 15px;
            margin-bottom: 10px;
            color: $white;
            min-height: $dashboard-card-min-height * 1.27;
            border-radius: 10px;

            .inner-card{
                // margin-top:1.4vh
                display: flex;
                flex-grow: 1;
                align-items: 'flex-start';
                &.repair-ticket{
                    align-items: flex-start;
                }
                .MuiGrid-item{
                    min-height: 98px;
                }
            }
            &.tvMode-height {
                min-height: ($tvModeHeight + 2.5) / 2.135;
            }
            &.card-npt{
                position: relative;
            }
            &.two-rows {
                min-height: $dashboard-card-min-height * 2.26;
                position: relative;
                &.tvMode {
                    min-height: $tvModeHeight + 2.5;
                }
                .footer {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    background-color: $dashboard-light-blue;
                    color: $white;
                    font-size: 0.8em;
                    width: 100%;
                    padding: 2px 10px 2px 10px;
                    box-sizing: border-box;
                    min-height: 35px;
                    height: 35px;
                    border-radius: 12px;
                    &.footer-tvmode {
                        min-height: 90px;
                    }
                    .time {
                        font-size: 1.5em;
                        font-weight: bold;
                    }

                    .unit {
                        position: relative;
                        top: -2px;
                        margin-left: 3px;
                    }
                }
                .footer-text {
                    div {
                        margin-top: 0.1vh;
                        // @include font-responsive(10,20);
                        // Need to check in the future, current would need to have base 9px font and 10 for other fields
                        font-size: calc(9px + (20 - 10) * ((100vw - 1169px) / (1830 - 1169)));
                    }
                }
                .footer-average{
                    transform: translate(4vw, 1vh);
                    text-align: center;
                }
                .footer-time{
                    text-align: center;
                    transform: translate(-7vw, 5vh);
                }
            }
            .tb-footer{
                border-left: 2px solid #264963;
            }

            .title {
                @extend .text-align-center;
                text-transform: uppercase;
                font-size: 1.3em;
                font-weight: 700;
                &.repair-ticket-title{
                    margin-bottom: 10px;
                }
                &.title-column {
                    margin-bottom: 15px;
                }
            }
            .header-text{
                background-color: #366a90;
                padding: 0;
                position: absolute;
                top: 53px;
                left: 9px;
                width: 100%;

                .table-headers{
                    padding: 8px;
                    color: #ffffff;
                    text-transform: uppercase;
                    font-size: 0.9em;
                    font-weight: 700;
                }
                .pl-20{
                    padding-left: 20px;
                }
            }

            .ticket-count-container{
                height: 130px;
            }

            .ticket-count {
                @extend .text-align-center;
                @include font-responsive(25, 30);
                margin-top: 10px;
                margin-bottom: 20px;
                margin-top: 30px;
                font-weight: 500;
                &.repair-ticket-count {
                    margin:5px;
                }
                &.tvMode{
                    @include font-responsive(40,40);
                }
            }

            .ticket-count-tvMode1{
                div{
                    transform: translate(2vw, 6vh);
                }
            }
            .ticket-count-tvMode2{
                div{
                    // transform: translate(4.25vw, -0.55vh);
                    transform: translate(4.25vw, 1.55vh);
                }
            }
            .ticket-count-tvMode3{
                div{
                    transform: translate(-3vw, 13vh);
                }
            }

            .ticket-status {
                @extend .text-align-center;
                @include font-responsive(14,22);
                margin-bottom: 20px;
                &.repair-ticket-status{
                    margin-bottom: 0px;
                }
                &.tvMode{
                    @include font-responsive(14,27);
                }
                &.repair-ticket{
                    @include font-responsive(14,21);
                    line-height: 1.2
                }
            }
        }

        .dashboard-menu {
            .menu-item {
                @extend .text-align-center;
                min-height: 35px;
                border-radius: 10px;
                transition: 0.3s ease-out all;
                padding: 20px;
                background-color: $dashboard-menu;
                color: $white;
                text-transform: uppercase;
                font-size: 1.4em;
                cursor: pointer;

                &:hover {
                    background-color: lighten($dashboard-menu, 5%);
                    text-decoration: none;
                }
                
                &.disabled{
                    cursor: not-allowed;

                    &:hover {
                        background-color: $dashboard-menu;
                        text-decoration: none;
                    }

                }
            }
        }

        .dashboard-menu-hidden{
            display: none;
        }

        .dashboard-list {
            max-height: 49vh;
            overflow: hidden;
            margin-top: 55px;
            @include font-responsive(10, 20);
            &.big-list {
                min-height: 49vh;
                overflow-y: scroll;
                padding-right: 10px;
                margin-bottom: 55px;
            }
            &.small-list {
                margin-top: 43px;
                min-height: 20.6vh;
            }
            &.tvMode {
                @include font-responsive(10, 25);
                margin-top: 6vh;
            }
            &.tvMode-small {
                @include font-responsive(8, 22);
                margin-top: 6vh;
                &.small-list {
                    min-height: 21.6vh;
                }
            }

            table {
                tr {
                    border-bottom: 1px solid transparent;

                    td {
                        padding: 2px 5px;
                        color: $white;
                        border: none;

                        &.machine-no {
                            @include cell-small-text;
                            width: 33px;
                        }

                        &.machine-name {
                            @include cell-small-text;
                        }

                        &.reported-time {
                            @include cell-small-text;
                            // @extend .text-align-right;
                            font-weight: bold;
                        }

                        &.npt {
                            // @extend .text-align-right;
                            font-size: 0.8em;

                            .time {
                                font-size: 1.5em;
                            }

                            .unit {
                                position: relative;
                                top: -3px;
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: $dashboard-light-blue;
                    }

                    &.warning {
                        background-color: $dashboard-red !important;
                        border-bottom-color: $dashboard-card;
                    }
                }
                .yellow-background {
                    background-color: $dashboard-yellow !important;
                    border-bottom-color: $dashboard-card;
                }
                .reopen-row.yellow-background > td {
                    color: $black !important;
                }
                .reopen-row > td {
                    vertical-align: baseline;
                }
                .cellReopened {
                    display: flex;
                    flex-direction: column;
                }
                .badgeReopen {
                    font-size: 10px;
                    padding: 1px 5px;
                    align-self: flex-start;
                    border-radius: 3px;
                    color: $white;
                    font-weight: bold;
                    background: $dashboard-red;
                }

                &.big-list {
                    tr {
                        &:nth-child(even) {
                            background-color: $dashboard-stripe-blue;
                        }

                        td {
                            padding: 2px 8px;
                        }
                    }
                }
            }
        }

        .big-list {
            scrollbar-width: thin;
            scrollbar-color: #1a2d3c #264963;
        }
        
        .big-list::-webkit-scrollbar {
            width: 12px;
        }
        
        .big-list::-webkit-scrollbar-thumb {
            background: #23394b;
            border-radius: 8px;
        }
        
        .big-list::-webkit-scrollbar-track {
            background: #264963;
            border-radius: 8px;
        }
        
    }
}


.build, #demo-customized-select-native{
    // margin-top: -5px;
    .language-label{
        color: $white;
        opacity: 0.79;
        position: relative;
        top:7px;
    }

    .MuiInputBase-root{
        // background: transparent !important;
        height: 40px;
        // width: 256px;
        width: 170px;
        background: #366A90 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px !important;

        &::after, &::before{
            display:none;
        }

        .MuiSelect-root{
            // padding: 0px 6px;
            padding: 11px 16px;
            display: inline-flex;
            color: $white;
            border-color: transparent;
            font-weight: 400 !important;
            font-size: 1.3rem !important;
            text-align:left;
        }

        .MuiSvgIcon-root{
            color: $white;
            // position: relative;
            // top: 10px;   
            position: absolute;
            top: 12px;    
            right: 12px;
        }

        .MuiSelect-root {
            background:transparent;
        }
    }
    .inlineFLex{
        display: inline-flex;
        padding: 0px 3px;
    }
}

// commented on rel-5.09.00, because it's just UI with static data which cannot be pushed to production. rel-5.09.00 will be released to production.
// ::-webkit-scrollbar {
//     width: 3px;
// }
  
// ::-webkit-scrollbar-track {
//     background: #0C090Aef; 
// }
   
// ::-webkit-scrollbar-thumb {
//     background: #888; 
// }
