@import '../../assets/scss/global.scss';

.template-control{
    margin-bottom:15px;

    &.no-margin-bottom{
        margin-bottom: 0px;
    }

    .template-table{
        margin-top: 10px;
        margin-bottom:15px;
        overflow-x: hidden;
        max-height: 150px;
        overflow-y: auto;
    
        .table-border-right{
            border-right: 4px solid #d7d7d7;
        }
    
        .toggle-padding{
            padding-right: 15px;
        }
    
        .link{
            color: $blue;
            text-decoration: none;
            font-weight: bold;
    
            &:hover{
                text-decoration: underline;
            }
        }

        table{
    
            thead{
        
                tr{
        
                    .MuiTableCell-head{
                        border: 0px !important;
                        padding: 3px;
                        font-size: 1.1rem !important;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        
            tbody{
        
                tr{
                    cursor: pointer;
        
                    &.selected-row{
                        background:$row-selected;
                    }
        
                    td{
                        border: 0px !important;
                        padding: 10px 3px;
                        font-size: 1.1rem;

                        a{
                            font-weight: bold;
                        }
        
                        &.status{
                            font-weight: bold;
        
                            &.active{
                                color: $status-active;
                            }
        
                            &.in-repair{
                                color: $status-in-repair;
                            }
        
                            &.not-working{
                                color: $status-not-working;
                            }
                        }
                    }
                }
            }
        }
    }

    .template-header{
        padding: 10px 15px;
        background-color: $template-header;

        .title{
            font-size: 1.4rem;
            font-weight: 500;
        }

        .action{
            font-size: 1.4rem;
            font-weight: 500;
            color: $blue;
            text-transform: uppercase;
            cursor: pointer;

            &:hover{
                text-decoration: underline;
            }
        }

        .toggle-container{
            position: relative;

            .toggle{
                cursor: pointer;
                position: absolute;
                top:-8px;
                right: -5px;
                height: 2em;
                width: 2em;
            }
        }
    }
    
    .no-result{
        @extend .text-align-center;
        margin-top:15px;
        font-size: 1.3em;
        font-weight: 500;
        width: 100%;
        // color: $blue;
    }
}