.chartjs-tooltip {
  min-width: 450px;
  display: flex;
  gap: 5px;
  .item  {
    background-color: rgba(0,0,0, 0.8);
    border-radius: 5px;
    padding: 8px 15px;
  };
  .item:first-child {
    width: 230px;
    height: 100%;
  };
  .item:last-child {
    flex: 1
  }
  h3 {
    color: #fff;
    font-size: 13px;
  }

  ;

  h5 {
    font-size: 12px;
    font-weight: normal;
    color: #ddd;
  }

  ;

  p {
    font-size: 12px;
    color: #ddd;
  }

  ;

  .box {
    margin: 5px 0;
  }

  ;

  .break-line {
    display: block;
    height: 1px;
    width: 100%;
    background: #ffffff;
    margin-bottom: 3px;
  }
  ;
  .legend-indicator{
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }
  .line-chart {
    background-repeat: no-repeat;
    background-position: bottom;
    width: 26px;
    height: 26px;
    background-size: cover;
  }
  .co{background-image: url("data:image/svg+xml,%3csvg id='Component_73_2' data-name='Component 73 %e2%80%93 2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20'%3e%3cdefs%3e%3cclipPath id='clip-path'%3e%3crect id='Rectangle_9926' data-name='Rectangle 9926' width='20' height='20' transform='translate(983 692)' fill='none' stroke='%23707070' stroke-width='1'/%3e%3c/clipPath%3e%3c/defs%3e%3cg id='Mask_Group_327' data-name='Mask Group 327' transform='translate(-983 -692)' clip-path='url(%23clip-path)'%3e%3cg id='Group_13019' data-name='Group 13019' transform='translate(154 351)'%3e%3cg id='Group_12887' data-name='Group 12887' transform='translate(0 1)'%3e%3cpath id='Path_1513' data-name='Path 1513' d='M20%2c0H0' transform='translate(829 350)' fill='rgba(0%2c0%2c0%2c0)' stroke='%23ea6fdc' stroke-width='4'/%3e%3cg id='Ellipse_623' data-name='Ellipse 623' transform='translate(835 346)' fill='white' stroke='%23ea6fdc' stroke-width='1'%3e%3ccircle cx='4' cy='4' r='4' stroke='none'/%3e%3ccircle cx='4' cy='4' r='3.5' fill='none'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") };
  .repair{background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' data-name='Component 73 – 2' viewBox='0 0 20 20'%3e%3cdefs%3e%3cclipPath id='clip-path'%3e%3cpath fill='none' stroke='%23707070' stroke-width='1' d='M0 0H20V20H0z' data-name='Rectangle 9926' transform='translate(983 692)'%3e%3c/path%3e%3c/clipPath%3e%3c/defs%3e%3cg clip-path='url(%23clip-path)' data-name='Mask Group 327' transform='translate(-983 -692)'%3e%3cg data-name='Group 13019' transform='translate(154 351)'%3e%3cg stroke='rgb(230, 203, 0)' data-name='Group 12887' transform='translate(0 1)'%3e%3cpath fill='rgba(0,0,0,0)' stroke-width='4' d='M849 350h-20' data-name='Path 1513'%3e%3c/path%3e%3cg fill='%23fff' stroke-width='1' data-name='Ellipse 623' transform='translate(835 346)'%3e%3ccircle cx='4' cy='4' r='4' stroke='none'%3e%3c/circle%3e%3ccircle cx='4' cy='4' r='3.5' fill='none'%3e%3c/circle%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e"); };
  .maintenance{background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' data-name='Component 73 – 2' viewBox='0 0 20 20'%3e%3cdefs%3e%3cclipPath id='clip-path'%3e%3cpath fill='none' stroke='%23707070' stroke-width='1' d='M0 0H20V20H0z' data-name='Rectangle 9926' transform='translate(983 692)'%3e%3c/path%3e%3c/clipPath%3e%3c/defs%3e%3cg clip-path='url(%23clip-path)' data-name='Mask Group 327' transform='translate(-983 -692)'%3e%3cg data-name='Group 13019' transform='translate(154 351)'%3e%3cg stroke='rgb(45, 153, 237)' data-name='Group 12887' transform='translate(0 1)'%3e%3cpath fill='rgba(0,0,0,0)' stroke-width='4' d='M849 350h-20' data-name='Path 1513'%3e%3c/path%3e%3cg fill='%23fff' stroke-width='1' data-name='Ellipse 623' transform='translate(835 346)'%3e%3ccircle cx='4' cy='4' r='4' stroke='none'%3e%3c/circle%3e%3ccircle cx='4' cy='4' r='3.5' fill='none'%3e%3c/circle%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");};
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  color: #fff;
  // background-color: rgba(0, 0, 0, .8);
  border-radius: 6px;
  transition: all 0.25s ease-in-out;
  pointer-events: none;
}

#chartjs-tooltip:after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 10px;
}

/* Top center */
#chartjs-tooltip.top.center:after {
  border-bottom-color: rgba(0, 0, 0, 0.8);
  top: -13px;
  left: 0;
  right: 0;
}

/* Top left */
#chartjs-tooltip.top.left:after {
  border-bottom-color: rgba(0, 0, 0, .8);
  left: 5px;
  top: -13px;
}

/* Top right */
#chartjs-tooltip.top.right:after {
  border-bottom-color: rgba(0, 0, 0, .8);
  right: 5px;
  top: -13px;
}

/* Bottom center */
#chartjs-tooltip.bottom.center:after {
  border-bottom-color: rgba(0, 0, 0, .8);
  top: -13px;
  right: 0;
  left: 0;
}

/* Bottom left */
#chartjs-tooltip.bottom.left:after {
  border-bottom-color: rgba(0, 0, 0, .8);
  top: -13px;
  left: 20px;
}

/* Bottom right */
#chartjs-tooltip.bottom.right:after {
  border-left-color: rgba(0, 0, 0, 0.8);
  top: 20px;
  right: -13px;
}

/* Center left */
#chartjs-tooltip.center.left:after {
  border-right-color: rgba(0, 0, 0, .8);
  margin: auto;
  left: -13px;
  top: 20px;
}

/* Center right */
#chartjs-tooltip.center.right:after {
  border-left-color: rgba(0, 0, 0, .8);
  margin: auto;
  right: -13px;
  top: 20px;
}

.chartjs-tooltip-key {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 7px;
}