@import '../../../../assets/scss/global.scss';

$black-text: #515151;
$tab-blue:rgba(22, 137, 250, 0.2);
:root{
    --mLeft: 10px
}
.repair-type-config{

    .problem-config{
        padding:15px;

        .title{
            font-weight: bold;
        }
    }

    .repair-type-config-body{
        padding: 5px;
        background: $white;
        color: $black-text;

        .title{
            font-size: 1.7rem;
            margin-left: var(--mLeft);
        }

        .MuiTabs-root{
            border-bottom: 1px solid #e1e1e1;
            margin-left: var(--mLeft);

            .MuiTab-root{
                font-size: 1.1rem;
                margin-right: 5px;
                border-bottom: 4px solid $tab-blue;
                opacity:1;
    
                &.Mui-selected{
                    color: $blue;
                }
            }

        }

        .MuiTabs-indicator{
            transition-duration: 0s;
            background-color: $blue;
            height: 4px;
        }
    }
}

.repair-type-modal{

    .MuiFormLabel-asterisk{
        color: red !important;
    }

    .MuiFormLabel-root{
        transition: 0.3s ease-out all;
    }

    .label-green{

        .MuiFormLabel-root{
            color: #4CAF50 !important;
        }
    }

}