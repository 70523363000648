@import '../../../../assets/scss/global.scss';

.ticket-form{
    
    .title-blue{
        color: $blue;
        font-size:3rem;
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .textarea{

        .MuiInputBase-root{ 
            height:50px !important;
        }
    }
    
    .button-group{
        margin-top: 40px;
        margin-bottom: 25px;
        text-align: right;
    
        .MuiButtonBase-root{
            box-shadow: none;
    
            &:hover{
                box-shadow: none;
            }
    
            &.MuiButton-contained{
                font-size: 1.5rem;
                color: $blue;
                margin-right: 15px;
                padding-right: 60px;
                padding-left: 60px;
    
                &:last-child{
                    margin-right: 0px;
                }
            }
    
            &.MuiButton-containedPrimary{
                background-color: $blue;
                color: $white;
                font-weight: bold;
    
                &:hover{
                    background-color: darken($blue,5%);
                }
            }
        }
    }
    
    .actions{
    
        .action-item{
            margin-right:15px;
    
            a{
                text-decoration: none;
            }
    
            span{
                text-align: center;
                padding-left: 5px;
                position: relative;
                color: $blue;
                font-size: 1.3rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: uppercase;
                cursor: pointer;
    
                &:hover{
                    text-decoration: underline;
                }
            }
    
            &:before{
                content: 'o';
                color: $blue;
                font-size: 1.3rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: lowercase;
                margin-right: 3px;
            }
        }
    }
    
    .filters{
    
        .filter-label{
            font-size: 1.2rem;
            text-transform: uppercase;
        }
    
        .filter-select{
    
            .form-select{
                
                .MuiSelect-select{
                    padding: 5px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    color: $blue;
                }
            }
        }
    }
    
    .form-component{
        margin-top:25px !important;
        margin-bottom:25px !important;
    
        .form-control{
            margin-top:5px;
    
            &:first-child{
                margin-top:0px;
            }
    
            .form-label{
                position:relative;
                top: 8px;
                color: $form-label-gray;
                font-size:1.4rem;
            }
    
            .value-label{
                position:relative;
                top: 8px;
                color: $black;
                font-size:1.4rem;
    
                strong{
                    font-weight: 700
                }
            }
        
            .MuiInputBase-root{
                height: 40px;
            }
        }
    }
}