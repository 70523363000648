.main-page-body{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
}

.main-page-body-lineOverview{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    background-color: #FFFFFF;
    height: 100%;
}