.main-page{
    overflow-x: hidden;
    // min-height: 550px;

    .footer{
        margin-top: 25px;
        text-align: right;
        padding-right: 15px;
        font-size: 0.75rem;
    }
}