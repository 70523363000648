.spans1 {
    position: absolute;
    left: 250px;
    font-weight: bold;
    color: white;
}
.spans2 {
    position: absolute;
    left: 421px;
    font-weight: bold;
    color: white;
}

.spans3 {
    position: absolute;
    left: 592px;
    font-weight: bold;
    color: white;
}