@import '../../../assets/scss/global.scss';

.anventory-authorized{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $login-background;
}
