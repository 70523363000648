@import '../../../assets/scss/global.scss';

.login{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $login-background;

    .login-container{
        @extend .text-align-center;
        position: absolute;
        background: $login-container;
        top: 51%;
        left: 51%;
        margin-left: -200px;
        margin-top: -250px;
        width: 400px;
        height: 500px;
        display: block;

        .login-header{
            margin-top: 30px;
            margin-bottom:30px;

            .label{
                line-height: 30px;
                color: $white;
                font-size: 1.4rem;

                strong{
                    font-size: 2rem;
                    font-size: 700;
                }
            }
        }

        .login-form{
            margin: 0 auto;
            width: 65%;

            .form-group{
                margin-bottom: 15px;

                .form-input{

                    .MuiInputBase-root{
                        border-radius: 5px !important;

                        input{
                            padding: 5px;
                            height: 30px;
                        }

                        &::after, &::before{
                            display:none;
                        }
                    }
                }
            }

            .form-label{
                text-align: left;
                color: $white;
                font-size: 1.5rem;
                margin-bottom: 10px;
                font-weight: 500;
            }

            .MuiButton-contained{
                background-color: $blue;
                color: $white;
                font-size: 1.5rem;
                text-transform: uppercase;
                border-radius: 5px !important;
                box-shadow: none;
                margin-top: 15px;
            }

            .ltlabs-logo{
                display: block;
                margin: 0 auto;
                margin-top: 35px;
                height:30px;
                width: 100px;

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }

            .language{
                margin-top: 15px;

                .language-label{
                    color: $white;
                    opacity: 0.79;
                    position: relative;
                    top:7px;
                }

                .MuiInputBase-root{
                    background: transparent !important;

                    &::after, &::before{
                        display:none;
                    }

                    .MuiSelect-root{
                        padding: 10px;
                        color: $white;
                        border-color: transparent;
                        text-align:left;
                    }

                    .MuiSvgIcon-root{
                        color: $white;
                    }
                }
            }
        }
    }

    .language-login{
        margin-top: 10px;
        float: right;
        .language-label{
            color: $white;
            opacity: 0.79;
            position: relative;
            top:7px;
        }

        .language-name{
            margin-left:15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            min-width: 30px;
            text-align: center;
        }

        .MuiInputBase-root{
            background: transparent !important;
            max-width:130px;

            &::after, &::before{
                display:none;
            }

            .MuiSelect-root{
                padding: 10px 21px;
                color: $white;
                border-color: transparent;
                text-align:left;
                font-weight: 400 !important;
                font-size: 1.3rem !important;
            }

            .MuiSvgIcon-root{
                color: $white;
                // position: relative;
                top: 10px;
            }

            .MuiSelect-root {
                background:transparent;
            }
        }
    }
}