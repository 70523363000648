@import '../../constants/styles/colors.scss';
@import '../fonts/fonts.scss';

*{
    padding:0px;
    margin: 0px;
    user-select: text;
    -moz-user-select: text;
    -webkit-user-select: text;
}

.react-confirm-alert-overlay{
    z-index: 2000;
}

.react-confirm-alert-body{
    
    h1{
        font-size: 2rem;
        line-height: 2rem;
    }
}

.disabled{
    cursor: not-allowed;
}

/* MATERIAL UI OVERRIDE */

.Mui-disabled{
    cursor: not-allowed;
}

.MuiButton-root{
    border-radius: 0px !important;
    min-width: 1px !important;
}

.MuiButton-root + .MuiButton-root{
    margin-left: 5px;
}

.MuiTablePagination-caption{
    font-size:1.1rem !important;
}

.MuiMenuItem-root{
    font-size: 1.3rem !important;
}

.MuiInputBase-root{
    font-size:1.3rem !important
}

.MuiInputBase-root{
    background-color: $white !important;
    border-radius: 0px !important;

    input::placeholder{
        color: $black !important;
        font-style: italic !important;
        opacity: 1;
    }
}


/* CUSTOM GLOBAL STYLES */

.text-align-left{
    display: block;
    text-align: left;
}

.text-align-center{
    display: block;
    text-align: center;
}

.text-align-right{
    display: block;
    text-align: right;
}

.form-control{
    .form-label{
        &.required{
            &:after{
                content: '*';
                color: red;
                position: absolute;
                top: -2px;
                font-size: 1.2rem;
                right: -10px;
            }
        }
    }
}

.highlight {
    background-color: yellow;
    color: black;
}