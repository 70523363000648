@import '../../../assets/scss/global.scss';

.table-paginate-ticket {
    .MuiSelect-select {
        font-size: 1.3rem;
        color: #000;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        background-color: transparent;
    };
    .MuiSelect-icon {
        top: 5px;
        color: rgba(0, 0, 0, 0.54);
        right: 0;
        position: absolute;
        pointer-events: none;
    }
}

.tickets-overview{

    .margin-top{
        margin-top: 15px;
    }

    .text-wrap{
        overflow-wrap: break-word;

        &.title{
            line-height: 38px;
        }
    }

    .text-ellipsis{
        overflow:hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        max-height: 50px;

        &.title{
            line-height: 38px;
        }
    }

    
    .multiline-ellipsis {
        overflow: hidden;
        height: 50px;
        line-height: 25px;
        max-width: 100%;
    }
    .multiline-ellipsis:before {
        content: "";
        float: left;
        width: 5px;
        height: 50px;
    }
    .multiline-ellipsis > *:first-child {
        float: right;
        width: 100%;
        margin-left: -5px;
    }
    .multiline-ellipsis:after {
        content: "\02026";
        box-sizing: content-box;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        float: right;
        position: relative;
        top: -25px;
        left: 100%;
        width: 3em;
        margin-left: -3em;
        padding-right: 5px;
        text-align: right;
        background-size: 100% 100%;/* 512x1 image,gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
        background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
        background: -webkit-gradient(linear,left top,right top,
        from(rgba(255,255,255,0)),to(white),color-stop(50%,white));
        background: -moz-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
        background: -o-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
        background: -ms-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
        background: linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
    }

    .create-ticket-modal{
        position: relative;
        
        .MuiInputBase-root{
            padding: 1px !important;
        }

        .close{
            position: absolute;
            right: 9px;
            top: 4px;
            height: 12px;
            width: 12px;
        }
    }
    
    .custom-date{
        height: 2.5rem;
        width: 2.5rem;
        cursor: pointer;
        position: relative;
        top: 0px;
        left: 5px;
        padding-right:5px;

        abbr{
            text-decoration: none;
        }
    }

    .calendar-buttons{
        margin-top: 0px;

        .MuiButton-label{
            font-size: 1.1rem;
            font-weight: 400;
        }

        .cancel{
            color: $blue;
            font-weight: 400;
        }

        .apply{
            background-color:#0F75BC;
            border-radius: 5px !important;
        }
    }

    .ticket-status{
        @extend .text-align-center;
        position:relative;
        font-size: 1.7rem;
        font-weight: 400;
        color: $white;
        padding: 10px 5px;
        background-color: $gray;
        margin-bottom:5px;

        &.reported{
            background-color: $status-not-working;
        }

        &.in-repair{
            background-color: $status-in-repair;
        }

        &.active{
            background-color: $status-active;
        }
    }

    .custom-date-control{
        position: relative;

        .date-input{
            position: absolute;
            top:40px;
            left:-150px;
            background: #F2F2F2;
            width: 500px;
            min-height: 260px;
            padding:10px;
            z-index: 9990;
            font-size: 'Segoe UI';

            .date-label{
                font-size:1.1rem;
                margin-bottom:10px;
            }

            .react-calendar__tile, .react-calendar__navigation__label, .react-calendar__navigation__arrow{
                background: transparent;
                border: none;
                padding: 10px;
                cursor: pointer;
            }

            .react-calendar__navigation__label{
                color: $blue;
                font-weight: 700;
            }
            
            .react-calendar__month-view__weekdays__weekday{

                abbr{
                    display: block;
                    text-decoration: none;
                    visibility:hidden;
                    position: relative;
                    left: 13px;

                    &:first-letter{
                        display: inline-block;
                        visibility:visible;
                        text-align: center;
                    }
                }
            }

            .react-calendar__navigation{
                background: #E6E6E6;
            }

            .react-calendar__tile--active{
                background-color: #5A75FF;
                color: $white;
            }
        }
    }

    .actions{

        .action-item{
    
            a{
                text-decoration: none;
            }
    
            span{
                text-align: center;
                position: relative;
                font-size: 1rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: uppercase;
                color: $blue;
                cursor: pointer;
    
                // &:hover{
                //     text-decoration: none;
                // }
            }
    
            // &:before{
            //     content: 'o';
            //     color: $blue;
            //     font-size: 1.3em;
            //     font-weight: bold;
            //     font-family: 'Arial';
            //     text-transform: lowercase;
            //     margin-right: 3px;
            // }
    
            &.disabled{
    
                span{
                    cursor: not-allowed;
                    color: $disabled;
    
                    &:hover{
                        text-decoration: none;
                    }
                }
    
                &:before{
                    color: $disabled;
                }
            }
        }
    }
    
    .MuiButton-outlinedSizeLarge {
        padding: 4px 21px !important;
        font-size: 0.9375rem;
    }
    
    .MuiButton-label {
        font-weight: 800;
    }
    
    .MuiButton-outlinedPrimary {
        color: #d1d6f1 !important;
        border: 1px solid rgba(199, 205, 239, 0.5) !important;
    }
    
    .filters{
    
        .filter-label{
            font-size: 1.2em;
            text-transform: uppercase;
        }
    
        .filter-select{
    
            .form-select{
                
                .MuiSelect-select{
                    padding: 0px;
                    font-size: 0.8em;
                    font-weight: bold;
                    color: $blue;
                }
            }
        }
    }
    
    .filterheight{
        height: 70px;
    }

    .blue-text{
        color:#0089ff !important;
    }

    .toggle-detail{
        padding-top: 25px;
        padding-left:15px;
        height: 78%;

        .header-detail{
            margin-bottom: 25px;

            .machineNo{
                font-weight:500;
                font-size:2.4rem;
                margin-bottom: 5px;
            }

            .status{
                font-weight:500;
                font-size:2.3rem;
                line-height: 38px;

                &.active{
                    color: $status-active;
                }

                &.in-repair{
                    color: $status-in-repair;
                }

                &.not-working{
                    color: $status-not-working;
                    font-size:2rem;
                }
            }
    
            .header-label{
                font-size: 1.7rem;
                font-weight: 400;
                margin-bottom: 5px;
            }

        }

        .body-detail{
            margin-bottom: 15px;

            .body-label{
                font-size: 1.2rem;
                margin-bottom:5px;
                color: $gray;
            }

            .body-value{
                font-size: 1.2rem;
                color: $black;
                white-space: nowrap;

                &.clickable{
                    color: $blue;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
            .body-values{
                font-size: 1.2rem;
                color: $black;
                // white-space: nowrap;

                &.clickable{
                    color: $blue;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        }
    }

}



.create-ticket-modal-input{

    .MuiInputBase-root{
        padding: 1px !important;
    }
}