@import '../../../assets/scss/global.scss';

.maintenance-checklist-modal{
    left: 1%;

    .close{
        color: $white;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }

    .title{
        color: $white;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .scrollable{
        height: 425px;

        .checklist{
            padding: 10px 10px 10px 10px;
            background: $white;
            height: 100%;
            overflow:hidden;
    
            .label{
                font-weight: 700;
                font-size: 1rem;
                margin-bottom: 10px;
            }
    
            .task{
                background-color: #F2F2F2;
    
                .task-title{
                    position: relative;
                    top: 5px;
                    font-size: 1.1rem;
                    font-weight: bold;
                }

                .arrow{
                    color: $blue;
                    font-size: 3.5rem;
                    position: absolute;
                    top: -2px;
                    right: -4px;
                }
            }
    
            .subtask{
                padding-left:12px;
                background-color: $white;
    
                .subtask-title{
                    position: relative;
                    top: 5px;
                    font-size: 1.1rem;
                }
            }
        }

    }
}