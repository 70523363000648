@font-face {
    font-family: 'Segoe UI';
    src: url('./SegoeUI/segoeui.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./SegoeUI/seguisb.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./SegoeUI/segoeuib.ttf');
    font-weight: 700;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');