@import '../../assets/scss/global.scss';

.main-table{
    margin-top: 10px;
    margin-bottom:15px;

    .table-tooltip{

        .MuiTooltip-root{
            font-size: 1.5rem;
        }
    }
    
    .MuiTableContainer-root{
        overflow-x: hidden;
    }

    .scroll{
        min-height: 440px;
        overflow-x: hidden;
    }

    .table-border-right{
        border-right: 4px solid #d7d7d7;
    }

    .toggle-padding{
        padding-right: 25px;
    }

    .link{
        color: $blue;
        text-decoration: none;
        font-weight: bold;

        &:hover{
            text-decoration: underline;
        }
    }

    thead{

        tr{

            th{
                border: 0px !important;
                padding: 0px 2px;
                font-size: 1rem;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    tbody{

        tr{
            cursor: pointer;

            &.selected-row{
                background:$row-selected;
            }

            &.inactive-row > td {
                color:$status-not-working-gray !important;
                font-style: italic;
            }

            &.inactive-row > td > a {
                color:$status-not-working-gray !important;
                font-style: italic;
            }

            td{
                text-overflow: ellipsis;
                overflow: hidden;
                border: 0px !important;
                padding: 0px 2px;
                max-width: 100px;
                font-size: 1rem;
                white-space: nowrap;

                &.status{
                    font-weight: bold;

                    &.active{
                        color: $status-active;
                    }

                    &.in-repair{
                        color: $status-in-repair;
                    }

                    &.not-working{
                        color: $status-not-working;
                    }
                    &.not-working-gray{
                        color: $status-not-working-gray;
                    }
                    &.repair{
                        color: $status-repair;
                    }
                    &.re-opened{
                        color: $status-re-opened;
                    }
                    &.maintenance{
                        color: $blue;
                    }
                }
            }
        }
    }
    
    .highlight {
        background-color: yellow;
        color: black;
    }
}

.table-pagination{
    // width: 250px;
    overflow:hidden;
}

.no-result{
    @extend .text-align-center;
    margin-top:15px;
    font-size: 1.3em;
    font-weight: 500;
    // color: $blue;
}

@media only screen and (max-width: 1365px){

    .main-table{

        .scroll{
            overflow-x: scroll;
        }
    }
}



@media only screen and (min-width: 1366px) and (max-width: 1499px){
    .main-table{
  
        .scroll{
          
          thead{
              tr{
                  th{
                      padding: 2px 2px;
                      font-size: 1rem;
                  }
              }
          }
  
          tbody{
              tr{
                  td{
                      padding: 2px 2px;
                      font-size: 1rem;
                  }
              }
          }
        }
    }
  }

  @media only screen and (min-width: 1500px) and (max-width: 1699px){
    .main-table{
  
        .scroll{
          
          thead{
              tr{
                  th{
                      padding: 4px 2px;
                      font-size: 1.2rem;
                  }
              }
          }
  
          tbody{
              tr{
                  td{
                      padding: 4px 2px;
                      font-size: 1.2rem;
                  }
              }
          }
        }
    }
  }

  @media only screen and (min-width: 1700px){
    .main-table{
  
        .scroll{
          
          thead{
              tr{
                  th{
                      padding: 6px 2px;
                      font-size: 1.3rem;
                  }
              }
          }
  
          tbody{
              tr{
                  td{
                      padding: 6px 2px;
                      font-size: 1.3rem;
                  }
              }
          }
        }
    }
  }

//   @media only screen and (min-width: 1601px) and (max-width: 2200px){
//     .main-table{
  
//         .scrollable{
          
//           thead{
//               tr{
//                   th{
//                       padding: 14px !important;
//                   }
//               }
//           }
  
//           tbody{
//               tr{
//                   td{
//                       padding: 14px !important;
//                   }
//               }
//           }
//         }
//     }
//   }