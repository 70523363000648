@import '../../../assets/scss/global.scss';

.crop-image-modal{
    left: 0%;
    padding: 0px !important;
    outline: none !important;

    .slider-container{
        margin-top:10px;
        padding-left: 30px;
        padding-right: 30px;

        .blue{
            color:#1689FA;
        }
    }

    .cropper-container{
        position:relative;
        width: 100%;
        height: 300px;
    }

    .button-group{
        margin-top: 10px;
        margin-bottom: 15px;
        text-align:center;

        .cancel{
            background-color: transparent;
            font-size: 1.2rem;
            border-radius: 4px !important;
        }

        .save{
            font-size: 1.2rem;
            background:#1689FA;
            color: $white;
            border-radius: 4px !important;
        }
    }

    .close{
        color: $white;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }

    .title{
        color: $black;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .scrollable{
        height: 425px;

        .checklist{
            padding: 10px 10px 10px 10px;
            background: $white;
            height: 100%;
            overflow:hidden;
    
            .label{
                font-weight: 700;
                font-size: 1rem;
                margin-bottom: 10px;
            }
    
            .task{
                background-color: #F2F2F2;
    
                .task-title{
                    position: relative;
                    top: 5px;
                    font-size: 1.1rem;
                    font-weight: bold;
                }

                .arrow{
                    color: $blue;
                    font-size: 3.5rem;
                    position: absolute;
                    top: -2px;
                    right: -4px;
                }
            }
    
            .subtask{
                padding-left:12px;
                background-color: $white;
    
                .subtask-title{
                    position: relative;
                    top: 5px;
                    font-size: 1.1rem;
                }
            }
        }

    }
}