@import '../../assets/scss/global.scss';

html, body{
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: 'Segoe UI';
}

.MuiAlert-root{
    font-size: 1.3em !important;
    z-index: 9999;

    &:first-letter{
      text-transform: capitalize;
    }

    .MuiAlert-message{

      &:first-letter{
        text-transform: capitalize;
      }
      
    }

    .MuiAlert-icon{
      font-size: 1.3em !important; 
    }
}

.menu-drawer{
  width: 350px;
  overflow: hidden;

      .logout{
          margin-bottom:15px;
          font-weight: bold;
          cursor: pointer;

          &:hover{
              text-decoration: underline;
          }
      }

      .drawer-header{
        background-color: $drawer-header;

        .drawer-back{
          position:relative;
          top: 13px;
          left: 5px;
          font-size: 3.5rem;
          cursor: pointer;
          margin: 0 auto;
          display: block;
        }

        .logo{
            margin: 0 auto;
            height: 70px;
            width: 118px;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
      }

      .drawer-body{
          overflow-y: auto;
          padding-top:15px;
          padding-left:15px;
          padding-right:15px;
          max-height: 90%;

          .drawer-link{
              cursor: pointer;
              transition: 0.3s ease-out all;
              font-size:1.5rem;
              text-transform: uppercase;
              text-decoration: none;
              color: $blue;
              font-weight: 700;
              margin-bottom: 15px;
              display: block;
              position:relative;

              &:hover{
                  text-decoration: underline;
              }

              &.disabled{
                  color: $gray;
                  cursor: not-allowed;

                  &:hover{
                    text-decoration: none;
                  }
              }

              .submenu-icon{
                position: absolute;
                top: -9px;
                right: 0px;
                font-size: 3rem;
                color: rgba(0,0,0,0.40);
              }
          }

          .link-group{

            .group-header{
                font-size: 1.4rem;
                font-weight: 500;
                text-transform: uppercase;
                color: rgba(0,0,0,0.40);
                padding-bottom: 5px;
                border-bottom: 1px solid rgba(0,0,0,0.40);
                margin-bottom: 15px;
            }
          }

          .sublink-group{

            .sublink{
                transition: 0.3s ease-out all;
                font-size:1.2rem;
                text-transform: uppercase;
                text-decoration: none;
                color: $blue;
                font-weight: 700;
                margin-bottom: 15px;
                display: block;
                position:relative;
                padding-left: 15px;

                &:hover{
                    text-decoration: underline;
                }

                .submenu-icon{
                  position: absolute;
                  top: -9px;
                  right: 0px;
                  font-size: 3rem;
                  color: rgba(0,0,0,0.40);
                }
            }
          }
      }
}

@media only screen and (min-width: 1333px) and (max-width: 1600px){
  body {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 2200px){
  body {
    font-size: 17px !important;
  }
}