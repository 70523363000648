@import '../../../../assets/scss/global.scss';

.machine-form{
    
    .date-input{

        .MuiButtonBase-root{
            position: relative;
            right:-17px;
        }
    }
    
    .checkbox-relative{
        position: relative;
        left: -11px;
    }
    
    .button-group{
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: right;
    
        .MuiButtonBase-root{
            box-shadow: none;
    
            &:hover{
                box-shadow: none;
            }
    
            &.MuiButton-contained{
                font-size: 1.5rem;
                color: $blue;
                margin-right: 15px;
                padding-right: 60px;
                padding-left: 60px;
    
                &:last-child{
                    margin-right: 0px;
                }
            }


    
            &.MuiButton-containedPrimary{
                background-color: $blue;
                color: $white;
                font-weight: bold;
    
                &:hover{
                    background-color: darken($blue,5%);
                }
            }
        }
    }
    
    .checkbox-input{
        position: relative;
    
        .MuiCheckbox-root{
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
    
    .actions{
    
        .action-item{
    
            span{
                text-align: center;
                padding-left: 5px;
                position: relative;
                color: $blue;
                font-size: 1.3rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: uppercase;
                cursor: pointer;
    
                &:hover{
                    text-decoration: underline;
                }
            }
    
            &:before{
                content: 'o';
                color: $blue;
                font-size: 1.3rem;
                font-weight: bold;
                font-family: 'Arial';
                text-transform: lowercase;
                margin-right: 3px;
            }
        }
    }
    
    .filters{
    
        .filter-label{
            font-size: 1.2rem;
            text-transform: uppercase;
        }
    
        .filter-select{
    
            .form-select{
                
                .MuiSelect-select{
                    padding: 5px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    color: $blue;
                }
            }
        }
    }

    .error-message{
        display: block;
        color: red;
    }
    
    .form-component{
        margin-top:25px !important;
        margin-bottom:25px !important;
    
        .form-control{
            margin-top:5px;
    
            &:first-child{
                margin-top:0px;
            }
    
            .form-label{
                position:relative;
                top: 8px;
                color: $form-label-gray;
                font-size:1.4rem;
                left: -15px;
                white-space: nowrap;
                text-overflow: ellipsis;
    
                &.required{
    
                    &:after{
                        content: '*';
                        color: red;
                        position: absolute;
                        top: -2px;
                        font-size: 1.2rem;
                        right: -10px;
                    }
                }
            }
        
            .MuiInputBase-root{
                height: 40px;
            }
        }
    }
}