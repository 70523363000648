@import '../../../assets/scss/global.scss';

.line-status-overview{

    .no-shadow{
        box-shadow: 'none !important'
    }

    .no-scroll-display{
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .gray-area{
        height: 40px;
        background-color: #F2F2F2;
        margin-bottom: 10px;
    }

    .carousel-dots{

        li{

            .BrainhubCarousel__dot{
                background: transparent;

                &:before{
                    width: 60px;
                    height: 12px;
                    border-radius: 50px;
                    background-color: $carousel-dot-default;
                }
                
                &.BrainhubCarousel__dot--selected{

                    &:before{
                        background-color: $carousel-dot-active;
                    }
                    
                }
                &:hover{
                    &:before{
                        background-color: $carousel-dot-hover;
                    }
                }
            }
        }
    }

    .line-overview{

        .slide{
            // position: absolute;
            // height: 350px;
            // width: 100%;
            padding-left: 25px;
            top: 0px;
        }

        .tabs{
            clear:both;

            .tab{
                float:left;
                width:70px;
                background-color: $line-tab;
                padding: 5px;
                margin-right: 10px;
                text-align: center;
                font-size: 1.3rem;
                cursor: pointer;

                &:last-child{
                    margin-right: 0px;
                }

                &.highlight{
                    background-color: $line-tab-highlight;
                    color: $white;
                    font-weight: 700;
                }
            }
        }

        .lines{
            clear:both;
            overflow: hidden;
            overflow-y: scroll;
            max-height: 250px;

            .line{
                width:70px;
                padding: 5px;
                padding-top: 15px;
                margin-right: 10px;
                float:left;
                position: relative;
                left: 0px;

                &:before{    
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 36px;
                    width: 2px;
                    height: 16px;
                    display: inline-block;
                    background: #D7D7D7;
                }

                .machine{
                    position: relative;
                    margin-bottom: 8px;
                    width: 15px;
                    height: 8px;
                    background-color: $line-machine-default;
                    cursor: pointer;

                    &.empty{
                        background-color: $line-machine-empty;
                    }

                    &:after{
                        content: '';
                        position: absolute;
                        bottom: -8px;
                        left: 6px;
                        width: 2px;
                        height: 8px;
                        display: inline-block;
                        background: $line-machine-default;
                    }

                    &:nth-child(10n){
                        margin-bottom: 16px;

                        &:after{
                            content: '';
                            position: absolute;
                            bottom: -16px;
                            left: 6px;
                            width: 2px;
                            height: 16px;
                            display: inline-block;
                            background: $line-machine-default;
                        }
                    }

                    &:last-child{
                        margin-bottom: 0px;

                        &:after{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .bottom-drawer-container{
        position: relative;
        width: 100%;
        height: 0px;
        overflow: hidden;

        .bottom-drawer{
            transition: 0.3s ease-out all;
            padding: 5px;
            position: absolute;
            background: #FBFBFB;
            visibility: none;
            height: 0px;
            overflow: hidden;
            width: 100%;
            bottom: 0px;

            .close{
                position: absolute;
                top: 5px;
                right: 15px;
                font-size: 1.5rem;
                cursor: pointer;

                .MuiSvgIcon-root{
                    height: 3rem !important;
                    width: 3rem !important;
                }
            }
        }

        &.displayed{
            height: 420px;

            .bottom-drawer{
                visibility: visible;
                height: 400px;
            }
        }

    }
}

.whiteBody {
    background-color: #FFFFFF;
}
