@import "react-big-calendar/lib/sass/styles.scss";
@import "../../../constants/styles/colors.scss";

.container {
    margin-top: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto 75vh;
    grid-template-areas:
        "h h h h h h h h h . . ."
        "c c c c c c c c c s s s";
}
.header {
    // background-color: red;
    margin-bottom: -10px;
    grid-area: h;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    .header-value {
        // margin-left: auto;
        // flex: 1 1 78px;
        // flex-basis: 78px;
        margin-right: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.7em;
    }
}

.panel {
    // div {
    //     user-select: none;
    // }
    text-align: center;
    text-transform: uppercase;
    &.sidebar {
        grid-area: s;
        margin-right: 50px;
        margin-left: 10px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 24px 30px auto auto;
        grid-template-rows: 24px 30px 30vh auto;
        grid-template-areas:
            "d d d d d d d d d d d d"
            "n n n n n n n n n n n n"
            "t t t t t t t t t t t t"
            "m m m m m m m m m m m m";
        .link {
            color: $blue;
            text-decoration: none;
            font-weight: bold;
            font-size: 0.8em;
            align-self: center;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            &.overview {
                align-self: flex-start;
                margin-left: 1vw;
                font-weight: bold;
                // height: 30px;
                font-size: 1em;
            }
            &.grow {
                flex: 0.7;
                text-align: start;
                // margin-left: 20px;
                margin-left: 1vw;
            }
        }
        .date-toolbar {
            grid-area: d;
            display: flex;
            align-items: center;
            margin-top: 10px;
            .nav-tool {
                flex: 0.5;
                button {
                    color: #0f75bc;
                    // height: 30px;
                }
                .custom-icon{
                    font-size: xx-large;
                }
            }
            .date-info {
                flex: 1;
                font-weight: 500;
                font-size: 1.2em;
            }
        }
        .numeric-date {
            grid-area: n;
            // margin-top: 20px;
            font-weight: bold;
            font-size: 1.2em;
        }
        .tickets-list {
            display: flex;
            flex-direction: column;
            grid-area: t;
            // justify-self: left;
            // margin-left: 10px;
        }
        .maintenance-list {
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            grid-area: m;
            max-height: 330px;
            overflow-y: scroll;
            .list-header {
                display: flex;
                // margin-left: 20px;
                // font-size: 0.6em;
                .value {
                    // color: $blue;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 0.8em;
                    align-self: center;
                }
                div:nth-child(1) {
                    // flex: 0.7;
                    margin-left: 1vw;
                }
                div:nth-child(2) {
                    // margin-left: 10px;
                    flex: 0.97;
                }
            }
        }
        .generate-ticket {
            align-self: flex-start;
            margin-left: 10px;
            color: $blue;
            font-weight: bold;
            height: 20px;
            box-shadow: inset 0 0 0 1px;
            &.bg-white {
                background-color: white;
            }
            &.custom-margin {
                margin-left: 20px;
            }
            &.empty-data {
                color: gray;
                // :hover {
                //     cursor: no-drop;
                // }
            }
        }
        .table-scroll {
            margin-top: 5px;
            max-height: -webkit-fill-available;
            display: block;
            overflow: auto;
            &.padded {
                margin-left: 10px;
            }
            &.no-top {
                margin-top: 0px;
            }
            .custom-checkbox {
                &.MuiIconButton-root:hover {
                    background-color: rgba(249, 249, 249, 0.04);
                }
                // &.MuiCheckbox-root {
                //     color: #008466;
                //     // color: #3f51b5
                // }
                &.MuiCheckbox-colorPrimary.Mui-checked {
                    color: #3c8dc7;
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
        }
        .table-container {
            display: flex;
            max-height: 20px;
        }
        .table-container-maintenance {
            display: flex;
            max-height: 20px;
            // margin-left: 20px;
            div:nth-child(2) {
                margin-left: 10px;
            }
        }
    }
    &.calendar {
        display: grid;
        grid-area: c;
        margin-left: 50px;
        .weekheader {
            background-color: #f2f2f2;
            font-size: 11px;
            font-weight: 500;

            .week {
                display: grid;
                align-content: center;
            }
        }
        .dateContainer {
            // :hover {
            //     background-color: #c5c4c4;
            //     // cursor: grab;
            // }
            .dateBox {
                display: grid;
                overflow: hidden;
                border: 0.5px solid #e5e5e5;
                background-color: #ffffff;
                &.today {
                    background-color: #0f75bc12;
                    opacity: 1;
                }
                &.no-date {
                    background-color: #f5f5f5;
                }
                .borderLine {
                    // box-sizing: border-box;
                    // border: 1.5px solid white;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 20px 20px auto;
                    grid-template-areas:
                        "h"
                        "i"
                        "v";
                    &.selected {
                        box-shadow: inset 0 0 0 1.5px #0f75bc;
                    }
                }
                .dateHeader {
                    font-size: 1.5em;
                    grid-area: h;
                    margin-top: 2px;
                }
                .dateInfo {
                    font-weight: bold;
                    grid-area: i;
                    font-size: 0.8em;
                    margin-top: 10px;
                }
                .dateValue {
                    font-size: 1.8em;
                    font-weight: 500;
                    grid-area: v;
                    align-self: center;
                }
            }
        }
    }
    &.MuiPaper-root {
        background-color: whitesmoke;
    }
}

// @media all and (max-width: 1130px) {
//     .date-info {
//         margin-top: 10px;
//         // background-color: green;
//     }
//     .numeric-date {
//         margin-top: 13px;
//     }
// }

@media all and (max-width: 802px) {
    .panel {
        &.sidebar {
            .tickets-list {
                .generate-ticket {
                    height: 34px;
                    margin-left: 0.5vw;
                }
                .table-scroll {
                    margin-left: -5px;
                }
            }
        }
    }
}
