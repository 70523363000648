@import '../../assets/scss/global.scss';

.main-page-header{
    background-color: $main-page-header;
    min-height: 97px;
    // padding-right: 10px;

    .line-status-tv-mode {
        font-size: 1.8rem;
        color: $dashboard-green;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        position:relative;
        left: 10px;
        display: inline-block;
        top: 4px;
        font-weight: bold;
        word-break: break-all;

        &.hidden{
            display: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .hidden{
        display:none !important;
    }

    &.white-header{
        background-color: $white;
    }
    
    .icon{
        position:relative;
        background-color: transparent;
        height: 25px;
        width: 100%;
        padding: 15px;

        &.icon-overview{

            &:before{
                content:  url('../../assets/img/one-column-icon.png');
                height: 25px;
                width: 25px;
                display: inline-block;
                text-align:center;
                position:relative;
                top: 2px;
                left: 0px;
            }

            &:hover, &.active{
                background-color: $blue;

                &:before{
                    content: url('../../assets/img/one-column-icon-white.png');
                }
            }
        }

        &.icon-two-column{

            &:before{
                content:  url('../../assets/img/two-column-icon.png');
                height: 25px;
                width: 25px;
                display: inline-block;
                text-align:center;
                position:relative;
                top: 2px;
                left: -2px;
            }

            &:hover, &.active{
                background-color: $blue;

                &:before{
                    content: url('../../assets/img/two-column-icon-white.png');
                }
            }
        }
    }

    .header-navigate{
        height: 55px;

        &.hamburger{
            background-color: $hamburger;

            &.white{
                background-color: $white;
            }

            &:hover{
                background-color: darken($hamburger,5%);
            }
    
            .hamburger-icon{
                height: 1.5em;
                width: 1.5em;
                position: relative;

                &::before{
                    content: '';
                    position:absolute;
                    top: -15px;
                    left: -14px;
                    height: 44px;
                    width: 44px;
                    background-image: url('../../assets/img/menu-black-48dp.svg');
                    background-position: center center;
                    background-size: contain;
                    display: inline-block;
                }
            }
        }

        &.arrow-back{
            background-color: $arrow-back;

            &:hover{
                background-color: darken($arrow-back,5%);
            }

            .arrow-back-icon{
                height: 1.5em;
                width: 1.5em;
            }
        }

    }

    .header-title{
        font-size: 2.2rem;
        text-transform: uppercase;
        position:relative;
        top:15px;
        padding-left:67px;
        text-align:left;

        &.area-title{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 1.8rem;
            font-weight: 700;
        }
    }

    .search-container{
        padding: 10px 0px;

        &.search-no-padding{
            padding: 0px;
        }

        .search-results{
            @extend .text-align-right;
            text-transform: uppercase;
            position: relative;
            top: 3px;
        }

        .search-input{

            .MuiInputBase-root{
                padding: 2px 0px !important;
                height: 30px;
                font-size: 1rem !important;

                input{
                    padding: 5px 5px;
                }
            }
        }
    }
}



@media only screen and (min-width: 1333px) and (max-width: 1600px){
    .main-page-header {
        .header-title{
            font-size: 2.2rem;
        }
    }
  }
  
  @media only screen and (min-width: 1601px) and (max-width: 2200px){
    .main-page-header {
        .header-title{
            font-size: 2.4rem;
        }
    }
  }