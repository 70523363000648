:root {
    --gray: #545454;
    --blue: #1e88e5;
    --container-padding: 10px;
    --header-color: #f7f8fa;
    --table-row-height: 22px;
    --image-area: 18px;
    --base-font: 1em;
    --hover-button: rgba(0, 0, 0, 0.04);
    --table-height: 58.6vh;
}
.MuiTooltip-tooltip {
    &.custom-tooltip {
        font-size: 12px !important;
        background-color: #1E3C50;
    }
}

.tableContainer-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000bf;
    z-index: 999;
    transition: all ease 100s;

    .MuiButtonBase-root {
        &.close {
            background: #fff;
            padding: 0px !important;
            top: 13vh !important;
            right: 18vw;
            position: absolute;
            .MuiSvgIcon-root {
                font-size: 2.5rem;
                transform: scale(1.2);
            }
        }
    }
    div {
        height: 100%;
        img {
            // margin-left: 10vw;
            // margin-top: 10vh;
            // width: 80%;
            // height: 80%;
            // object-fit: contain;
            margin-left: 14%;
            margin-top: 4%;
            width: 73%;
            height: 80%;
            object-fit: contain;
        }
    }
}
.search-container {
    display: flex;
    margin: var(--container-padding);
    .search-bar {
        display: inherit;
        border: 1px solid #dfdfdf;
        padding-right: 15px;
        border-radius: 3px;
        height: 26px;
        flex: 0.2;
        input {
            width: 15vw;
            font-size: small;
            &::placeholder {
                font-weight: 400;
                font-style: normal !important;
                color: '#515151' !important;
                opacity: 0.5;
            }
        }
    }
    .search-icon {
        display: inherit;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
    .add-button {
        margin-left: 10px;
        border-radius: 3px;
        border: 0.6px solid var(--blue);
        button {
            color: var(--blue);
            // border: 1px solid var(--blue);
            border: none;
            height: 26px;
            flex: 0.03;
            &.MuiButton-outlinedPrimary:hover {
                // border: 1px solid var(--blue);
                border: none;
            }
            .MuiButton-startIcon {
                margin-right: 3px;
                margin-left: -10px;
            }
            .MuiButton-label {
                margin-right: -6px;
            }
        }
        &.disabled {
            border: 0.6px solid #d4d4d4;
            button {
                color: #d4d4d4;
            }
        }
    }
}
.tableContainer {
    margin: var(--container-padding);
    &.horizontal-scroll {
        .MuiTableContainer-root {
            height: var(--table-height);
            overflow-y: hidden;
            &::-webkit-scrollbar {
                display: block;
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: var(--gray);
            }
        }
    }
    .MuiTableContainer-root {
        height: var(--table-height);
        &::-webkit-scrollbar {
            display: none;
            // width: 5px;
            // height: 5px;
        }
        // &::-webkit-scrollbar-thumb {
        //     border-radius: 10px;
        //     background: var(--gray);
        // }
        &.table-child {
            height: none;
        }
    }
    .table-image {
        cursor: pointer;
        .image-src {
            height: var(--image-area);
            width: var(--image-area);
            border: 1px solid;
        }
    }
    .paginations-right {
        display: flex;
        justify-content: flex-end;
    }

    th,
    td {
        font-size: var(--base-font);
        &.MuiTableCell-root {
            // position: sticky;
            // top: 0;
            padding: 8px;
            color: var(--gray);
        }
    }
    td {
        &.bordered {
            border-top: 1px solid rgba(224, 224, 224, 1);
            &.align-end {
                text-align: end;
                padding-right: 0;
                button {
                    margin-right: 10px;
                    &.child-button {
                        margin-right: 3px;
                    }
                }
            }
            &.last-item{
                border-bottom: 1px solid rgba(224, 224, 224, 1);
            }
            &.border-top-none{
                border-top: none;
            }
            &.no-collapse {
                // width: 40px;
                width: 6%;
            }
            &.parent-no-collapse {
                width: 2%;
            }
        }
        &.fixed-height {
            height: 20px;
        }
        &.fit-height {
            height: var(--table-row-height);
        }
        &.MuiTableCell-root {
            border-bottom: none;
        }
        .MuiSvgIcon-root {
            font-size: 1.5rem;
        }
        &.text-bold {
            font-weight: 500;
        }
    }
    th {
        z-index: 2;
        &.MuiTableCell-root {
            font-weight: bold;
            position: sticky;
            top: 0;
            background-color: var(--header-color);
        }
    }
    table {
        &.MuiTable-root {
            text-align: left;
            position: relative;
            border-collapse: collapse;
        }
    }
    .MuiTableHead-root {
        &.tableHeader {
            background-color: var(--header-color);
        }
        .MuiTableCell-head {
            &.headerValue {
                white-space: nowrap;
                font-weight: bold;
                text-transform: uppercase;
                color: var(--gray);
                &.link {
                    font-weight: 500;
                    color: var(--blue);
                    &:hover {
                        text-decoration: underline;
                    }
                    cursor: pointer;
                }
                &.disabled {
                    font-weight: 500;
                    color: #bfc0c2;
                    &:hover {
                        text-decoration: underline;
                    }
                    cursor: pointer;
                }
            }
        }
    }
}

.customIcon {
    color: #525252;
    &.blue {
        color: var(--blue);
    }
    &.light-gray {
        color: #b5b3b3ea;
    }
    &.large {
        font-size: 1.8rem !important;
    }
    &.helper-tooltip {
        vertical-align: revert;
        font-size: 1rem;
    }
    &.checkbox-blue {
        &.Mui-checked {
            color: var(--blue);
        }
        &.MuiCheckbox-colorSecondary:hover {
            background-color: var(--hover-button);
        }
        &.disabled {
            &.Mui-disabled {
                color: var(--blue) !important;
            }
        }
    }
    &.radio-blue {
        &.MuiIconButton-colorSecondary:hover {
            background-color: var(--hover-button);
        }
        &.MuiRadio-colorSecondary.Mui-checked {
            color: var(--blue);
        }
    }
}

// @media screen and (max-width: 1920px) {
//     :root {
//         --base-font: 20px;
//     }
// }

// @media screen and (max-width: 1440px) {
//     :root {
//         --base-font: 16px;
//     }
// }

// @media screen and (max-width: 1280px) {
//     :root {
//         --base-font: 14px;
//     }
// }

// @media screen and (max-width: 1024px) {
//     :root {
//         --base-font: 12px;
//     }
// }
